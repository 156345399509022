<script type="text/javascript">
    // 定义一些公共的属性和方法
//阿里云

     const http = 'https://gl.xingqi.co';
     const ht =   'https://wap.xingqi.co';
     const fmht =  'https://wap.xingqi.co/list';
     const xzht = 'https://wap.xingqi.co';
     const xfht = 'https://wap.xingqi.co';
     const syt = 'http://wap.xingqi.co';
     const https = true;


//北京
/*
const http = 'https://gl.xingqi.co';
const ht = 'https://wap.xingqi.co';
const  fmht = 'https://wap.xingqi.co/list';
const xzht = 'https://wap.xingqi.co';
const xfht = 'https://wap.xingqi.co';
const syt = 'https://wap.xingqi.co';
const https = true;
*/
/*
const http = 'https://gl.xingqi.co';
 const ht = 'https://wap.xingqi.co';
const  fmht = 'https://wap.xingqi.co';
 const xzht = 'https://wap.xingqi.co';
 const xfht = 'https://wap.xingqi.co';
const syt = 'https://wap.xingqi.co';
 const https = true;
*/





 //测试

/*
 const http = 'https://gl.xingqi.co';
 const ht =   'https://wap.xingqi.co';
 const fmht = 'https://wap.xingqi.co';
 const xzht = 'https://wap.xingqi.co';
 const xfht = 'https://wap.xingqi.co';
 const  syt = 'https://wap.xingqi.co';
 const https = true;
*/
//https


/*
     const http = 'https://wap.xingqi.co';
     const ht =   'https://wap.xingqi.co';
     const fmht =  'https://wap.xingqi.co';
     const xzht = 'https://wap.xingqi.co';
     const xfht = 'https://wap.xingqi.co'; 
     const syt = 'https://wap.xingqi.co';
      const https = true;
*/


//https2

/*
     const http = 'https://gl.xingqi.co';
     const ht =   'https://wap.xingqi.co';
     const fmht =  'https://wap.xingqi.co';
     const xzht = 'https://wap.xingqi.co';
     const xfht = 'https://wap.xingqi.co';
      const https = true;
*/

     const ym_arr=['wap.xingqi.co','wap.xingqi.co','wap.xingqi.co'];

    function commonFun() {
      
    }
    // 暴露出这些属性和方法
    export default {
        http,
        ht,
        fmht,
        xzht,
        xfht,
        commonFun,
        https,
        ym_arr,
        syt
    }
</script>
