import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)






/*
const requireViews  = require.context('../views', true, /\w+\.vue$/);

const routes = requireViews.keys().map((viewPath, index) => {
  let viewName = viewPath.split('.vue')[0].substr(2);
    return {
      path: viewName === 'index' ? '/' : `/${viewName}`,
      name: viewName,
      component: () => import(`../views/${viewName}.vue`)
    }
});
*/



const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/list',
    name: 'List',
    component: () => import('../views/List.vue')
  },
  {
    path: '/test/detail',
    name: 'Detail',
    component: () => import('../views/test/Detail.vue'),
    beforeRouteLeave:(to, from, next) => {
      // ...
    }
    
  }
  ,    {
    path: '/test/detail1',
    name: 'Detail',
    component: () => import('../views/test/Detail.vue'),
    beforeRouteLeave:(to, from, next) => {
      // ...
    }
    
  }
  ,   {
    path: '/zs/list',
    name: 'zs',
    component: () => import('../views/zs/list.vue')
  } 
  ,{
    path: '/zs/hb',
    name: 'zs',
    component: () => import('../views/zs/Hb.vue')
  } 
  ,
  {
    path: '/test/index',
    name: 'Test',
    component: () => import('../views/test/Index.vue')
  } ,  {
    path: '/test/dh',
    name: 'Choose',
    component: () => import('../views/test/dh.vue')
  },  {
    path: '/test/dh1',
    name: 'dh1',
    component: () => import('../views/test/dh1.vue')
  },  {
    path: '/test/dh2',
    name: 'dh2',
    component: () => import('../views/test/dh2.vue')
  }, 
  {
    path: '/test/conf',
    name: 'Conf',
    component: () => import('../views/test/Conf.vue')
  },
  {
    path: '/test/choose',
    name: 'Choose',
    component: () => import('../views/test/Choose.vue')
  },  {
    path: '/test/choose1',
    name: 'Choose1',
    component: () => import('../views/test/choose1.vue')
  }, {
    path: '/test/choose2',
    name: 'Choose2',
    component: () => import('../views/test/choose2.vue')
  },
  {
    path: '/test/pay',
    name: 'Pay',
    component: () => import('../views/test/Pay.vue')
  },
  {
    path: '/test/pay1',
    name: 'Pay',
    component: () => import('../views/test/Pay.vue')
  },
  {
    path: '/test/pay2',
    name: 'Pay',
    component: () => import('../views/test/Pay.vue')
  },
  {
    path: '/test/suc',
    name: 'suc',
    component: () => import('../views/test/Suc.vue')
  },{
    path: '/test/suc1',
    name: 'suc',
    component: () => import('../views/test/Suc1.vue')
  },
  {
    path: '/test/Null',
    name: 'null',
    component: () => import('../views/test/Null.vue')
  },
  {
    path: '/res/res1',
    name: 'Res1',
    component: () => import('../views/res/Res1.vue')
  },  {
    path: '/res/res100',
    name: 'Res1',
    component: () => import('../views/res/Res100.vue')
  }, {
    path: '/res/res101',
    name: 'Res1',
    component: () => import('../views/res/Res101.vue')
  },{
    path: '/res/dn',
    name: 'Res1',
    component: () => import('../views/res/dn.vue')
  },
  {
    path: '/res/res001',
    name: 'res1001',
    component: () => import('../views/res/Res1.vue')
  },
  {
    path: '/res/res002',
    name: 'res1001',
    component: () => import('../views/res/Res1.vue')
  },
  {
    path: '/res/ct',
    name: 'Ct',
    component: () => import('../views/res/Ct.vue')
  }, 
  
  {
    path: '/res/cuoti',
    name: 'cuoti',
    component: () => import('../views/res/Cuoti.vue')
  },
  {
    path: '/res/res2',
    name: 'Res2',
    component: () => import('../views/res/Res2.vue')
  },
  {
    path: '/res/res3',
    name: 'Res3',
    component: () => import('../views/res/Res3.vue')
  },
  {
    path: '/res/res4',
    name: 'Res4',
    component: () => import('../views/res/Res4.vue')
  },{
    path: '/res/res4_1',
    name: 'Res4_1',
    component: () => import('../views/res/res4_1.vue')
  },
  {
    path: '/res/res5',
    name: 'Res5',
    component: () => import('../views/res/Res5.vue')
  },
  {
    path: '/res/res6',
    name: 'Res6',
    component: () => import('../views/res/Res6.vue')
  },{
    path: '/res/res7',
    name: 'Res7',
    component: () => import('../views/res/Res7.vue')
  },{
    path: '/res/res7_1',
    name: 'Res7_1',
    component: () => import('../views/res/res7_1.vue')
  },
  {
    path: '/res/res8',
    name: 'Res8',
    component: () => import('../views/res/Res8.vue')
  },
  {
    path: '/res/res9',
    name: 'Res9',
    component: () => import('../views/res/Res9.vue')
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/user1',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/user2',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/user3',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/user4',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/user5',
    name: 'User',
    component: () => import('../views/User.vue')
  },
{
    path: '/user6',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/user7',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/user8',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/user9',
    name: 'User',
    component: () => import('../views/User.vue')
  }, {
    path: '/user10',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/user11',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/user12',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/user13',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/user14',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/user15',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/user16',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/user17',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/user18',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/user19',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/user20',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/user21',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/user22',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/user23',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/user24',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/user25',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/user/yinsi',
    name: 'Yinsi',
    component: () => import('../views/user/Yinsi.vue')
  }, {
    path: '/user/yhxy',
    name: 'Yhxy',
    component: () => import('../views/user/Yhxy.vue')
  },
  {
    path: '/user/fankui',
    name: 'fankui',
    component: () => import('../views/user/Fankui.vue')
  },{
    path: '/user/my2',
    name: 'my',
    component: () => import('../views/user/My.vue')
  },{
    path: '/user/my1',
    name: 'my',
    component: () => import('../views/user/My.vue')
  },{
    path: '/user/my',
    name: 'my',
    component: () => import('../views/user/My.vue')
  },{
    path: '/user/my3',
    name: 'my',
    component: () => import('../views/user/My.vue')
  },{
    path: '/user/my4',
    name: 'my',
    component: () => import('../views/user/My.vue')
  },{
    path: '/user/my5',
    name: 'my',
    component: () => import('../views/user/My.vue')
  },{
    path: '/user/my6',
    name: 'my',
    component: () => import('../views/user/My.vue')
  },{
    path: '/user/my7',
    name: 'my',
    component: () => import('../views/user/My.vue')
  },
  {
    path: '/t/tst',
    name: 'tst',
    component: () => import('../views/t/Tst.vue')
  },
  {
    path: '/t/H',
    name: 'h',
    component: () => import('../views/t/H.vue')
  },
  {
    path: '/t/wxbridge',
    name: 'Wxbridge',
    component: () => import('../views/t/Wxbridge.vue')
  },
  {
    path: '/res1/res10',
    name: 'res_mbti',
    component: () => import('../views/res1/Res10.vue')
  },
  {
    path: '/res1/res_mbti28',
    name: 'res_mbti',
    component: () => import('../views/res1/mbti28.vue')
  },
  {
    path: '/res1/res_mbti48',
    name: 'res_mbti',
    component: () => import('../views/res1/mbti48.vue')
  },
  {
    path: '/test/detail_mbti',
    name: 'res_mbti',
    component: () => import('../views/test/Detail_mbti.vue')
  },
  {
    path: '/res1/res11',
    name: 'res_fenlu',
    component: () => import('../views/res1/Res11.vue')
  },
  {
    path: '/res1/res12',
    name: 'res_jiaolv',
    component: () => import('../views/res1/Res12.vue')
  },
  {
    path: '/res1/res13',
    name: 'res_kongzhiyu',
    component: () => import('../views/res1/Res13.vue')
  },
  {
    path: '/res1/res14',
    name: 'res_kongzhiyu',
    component: () => import('../views/res1/Res14.vue')
  },
  {
    path: '/res1/res15',
    name: 'res_nishang',
    component: () => import('../views/res1/Res15.vue')
  },
  {
    path: '/res1/res16',
    name: 'res_qianyishihuaxiang',
    component: () => import('../views/res1/Res16.vue')
  },
  {
    path: '/res1/res17',
    name: 'res_qingshang',
    component: () => import('../views/res1/Res17.vue')
  },
  {
    path: '/res1/res18',
    name: 'res_shejiaojiaolv',
    component: () => import('../views/res1/Res18.vue')
  },
  {
    path: '/res1/res19',
    name: 'res_zizhunxin',
    component: () => import('../views/res1/Res19.vue')
  },{
    path: '/res2/res21',
    name: 'res_qingxuguaishou',
    component: () => import('../views/res2/Res21.vue')
  },{
    path: '/res2/res22',
    name: 'res_renshengjuben',
    component: () => import('../views/res2/Res22.vue')
  },{
    path: '/res2/res23',
    name: 'res_renshengjuben',
    component: () => import('../views/res2/Res23.vue')
  },{
    path: '/res2/res24',
    name: 'res_res4',
    component: () => import('../views/res2/Res24.vue')
  },
  {
    path: '/res2/res25',
    name: 'res_res5',
    component: () => import('../views/res2/Res25.vue')
  },{
    path: '/res2/res26',
    name: 'res_res26',
    component: () => import('../views/res2/Res26.vue')
  },{
    path: '/res2/res27',
    name: 'res_res27',
    component: () => import('../views/res2/Res27.vue')
  },{
    path: '/res2/res28',
    name: 'res_res28',
    component: () => import('../views/res2/Res28.vue')
  },{
    path: '/res2/res29',
    name: 'res_res29',
    component: () => import('../views/res2/Res29.vue')
  },{
    path: '/res2/res20',
    name: 'res_res29',
    component: () => import('../views/res2/Res20.vue')
  },{
    path: '/res3/res30',
    name: 'res_res30',
    component: () => import('../views/res3/Res30.vue')
  },
  {
    path: '/user/vip',
    name: 'vip',
    component: () => import('../views/user/Vip.vue')
  },{
    path: '/user/buyvip',
    name: 'buyvip',
    component: () => import('../views/user/Buyvip.vue')
  },
  {
    path: '/user/up',
    name: 'up',
    component: () => import('../views/user/Up.vue')
  }
  ,
  {
    path: '/name/index',
    name: 'name',
    component: () => import('../views/other/Name.vue')
  } ,
  {
    path: '/test/zfcg',
    name: 'zfcg',
    component: () => import('../views/test/Zfcg.vue')
  },
  {
    path: '/ss',
    name: 'ss',
    component: () => import('../views/Ss.vue')
  },
  ,{
    path: '/res3/res32',
    name: 'res_res32',
    component: () => import('../views/res3/Res32.vue')
  },{
    path: '/res3/res34',
    name: 'res_res34',
    component: () => import('../views/res3/Res34.vue')
  },{
    path: '/res3/res31',
    name: 'res_res31',
    component: () => import('../views/res3/Res31.vue')
  },{
    path: '/res3/res38',
    name: 'res_res38',
    component: () => import('../views/res3/Res38.vue')
  },{
    path: '/res3/res39',
    name: 'res_res39',
    component: () => import('../views/res3/Res39.vue')
  },{
    path: '/res3/res36',
    name: 'res_res36',
    component: () => import('../views/res3/Res36.vue')
  },{
    path: '/res3/res33',
    name: 'res_res33',
    component: () => import('../views/res3/Res33.vue')
  },{
    path: '/res3/res35',
    name: 'res_res35',
    component: () => import('../views/res3/Res35.vue')
  },{
    path: '/res3/res37',
    name: 'res_res37',
    component: () => import('../views/res3/Res37.vue')
  },{
    path: '/res4/res41',
    name: 'res_res41',
    component: () => import('../views/res4/Res41.vue')
  },{
    path: '/res4/res40',
    name: 'res_res40',
    component: () => import('../views/res4/Res40.vue')
  },{
    path: '/res4/res42',
    name: 'res_res42',
    component: () => import('../views/res4/Res42.vue')
  },{
    path: '/res4/res43',
    name: 'res_res43',
    component: () => import('../views/res4/Res43.vue')
  },{
    path: '/res4/res44',
    name: 'res_res44',
    component: () => import('../views/res4/Res44.vue')
  },{
    path: '/res4/res45',
    name: 'res_res45',
    component: () => import('../views/res4/Res45.vue')
  },{
    path: '/res4/res46',
    name: 'res_res46',
    component: () => import('../views/res4/Res46.vue')
  },{
    path: '/res4/res47',
    name: 'res_res47',
    component: () => import('../views/res4/Res47.vue')
  },{
    path: '/res4/res48',
    name: 'res_res48',
    component: () => import('../views/res4/Res48.vue')
  },{
    path: '/res4/res49',
    name: 'res_res49',
    component: () => import('../views/res4/Res49.vue')
  },{
    path: '/res5/res50',
    name: 'res_res50',
    component: () => import('../views/res5/Res50.vue')
  },{
    path: '/res5/res51',
    name: 'res_res51',
    component: () => import('../views/res5/Res51.vue')
  },{
    path: '/res5/res54',
    name: 'res_res54',
    component: () => import('../views/res5/Res54.vue')
  },{
    path: '/res5/res57',
    name: 'res_res57',
    component: () => import('../views/res5/Res57.vue')
  } ,{
    path: '/test/wx_send_pay',
    name: 'wx_send_pay',
    component: () => import('../views/test/wx_send_pay.vue')
  } 
  ,{
    path: '/res5/res58',
    name: 'res_res58',
    component: () => import('../views/res5/Res58.vue')
  },{
    path: '/res5/res59',
    name: 'res_res59',
    component: () => import('../views/res5/Res59.vue')
  },{
    path: '/res5/res55',
    name: 'res_res55',
    component: () => import('../views/res5/Res55.vue')
  },{
    path: '/res5/res52',
    name: 'res_res52',
    component: () => import('../views/res5/Res52.vue')
  },{
    path: '/res6/res63',
    name: 'res_res63',
    component: () => import('../views/res6/res63.vue')
  },{
    path: '/res6/res63_1',
    name: 'res_res63_1',
    component: () => import('../views/res6/res63_1.vue')
  },{
    path: '/res6/res64',
    name: 'res_res64',
    component: () => import('../views/res6/res64.vue')
  },{
    path: '/res6/res64_1',
    name: 'res_res64_1',
    component: () => import('../views/res6/res64_1.vue')
  },{
    path: '/res6/res65',
    name: 'res_res65',
    component: () => import('../views/res6/res65.vue')
  },{
    path: '/res6/res65_1',
    name: 'res_res65_1',
    component: () => import('../views/res6/res65_1.vue')
  },{
    path: '/res6/res66',
    name: 'res_res66',
    component: () => import('../views/res6/res66.vue')
  },{
    path: '/res6/res66_1',
    name: 'res_66_1',
    component: () => import('../views/res6/res66_1.vue')
  },{
    path: '/res5/res53',
    name: 'res_res53',
    component: () => import('../views/res5/Res53.vue')
  },{
    path: '/res5/res56',
    name: 'res_res56',
    component: () => import('../views/res5/Res56.vue')
  },{
    path: '/fx/hb',
    name: 'res_hb',
    component: () => import('../views/fx/Hb.vue')
  },{
    path: '/fx/fxlist1',
    name: 'res_fxlist',
    component: () => import('../views/fx/Fxlist.vue')
  }
  ,{
    path: '/fx/zslist',
    name: 'res_zslist',
    component: () => import('../views/fx/Zslist.vue')
  }
  ,{
    path: '/fx/main',
    name: 'res_fxlist',
    component: () => import('../views/fx/Main.vue')
  } ,{
    path: '/fx/tx',
    name: 'res_fxlist',
    component: () => import('../views/fx/Tx.vue')
  },{
    path: '/fx/sy',
    name: 'res_fxlist',
    component: () => import('../views/fx/Sy.vue')
  },{
    path: '/fx/zq',
    name: 'res_dy',
    component: () => import('../views/fx/Zq.vue')
  },{
    path: '/fx/cash',
    name: 'res_cash',
    component: () => import('../views/fx/Cash.vue')
  },{
    path: '/fx/wt',
    name: 'res_wt',
    component: () => import('../views/fx/Wt.vue')
  },
  {
    path: '/card/list',
    name: 'card',
    component: () => import('../views/card/list.vue')
  },  {
    path: '/card/zs_list',
    name: 'card',
    component: () => import('../views/card/zs_list.vue')
  }, {
    path: '/card/zs_hb',
    name: 'card',
    component: () => import('../views/card/zs_hb.vue')
  },{
    path: '/res1/bawei_pay',
    name: 'card',
    component: () => import('../views/res1/bawei_pay.vue')
  },{
    path: '/res1/love_pay',
    name: 'card',
    component: () => import('../views/res1/love_pay.vue')
  },{
    path: '/res1/love',
    name: 'love',
    component: () => import('../views/res1/love.vue')
  },{
    path: '/test/choose_mbti',
    name: 'choose_mbti',
    component: () => import('../views/test/choose_mbti.vue')
  },{
    path: '/test/mbti',
    name: 'love',
    component: () => import('../views/test/mbti.vue')
  },{
    path: '/res1/bawei',
    name: 'card',
    component: () => import('../views/res1/bawei.vue')
  },{
    path: '/test/gzgzh',
    name: 'gzgzh',
    component: () => import('../views/test/gzgzh.vue')
  },{
    path: '/res/res102',
    name: 'gzgzh',
    component: () => import('../views/res/Res102.vue')
  },
  {
    path: '/res/res103',
    name: 'gzgzh',
    component: () => import('../views/res/Res103.vue')
  },
  {
    path: '/res/tuteng',
    name: 'gzgzh',
    component: () => import('../views/res/tuteng.vue')
  },{
    path: '/res6/res60',
    name: 'res_res60',
    component: () => import('../views/res6/res60.vue')
  },{
    path: '/res6/res61',
    name: 'res_res61',
    component: () => import('../views/res6/res61.vue')
  },{
    path: '/res6/kongju',
    name: 'res_res61',
    component: () => import('../views/res6/kongju.vue')
  },{
    path: '/res6/res61_1',
    name: 'res_res61',
    component: () => import('../views/res6/res61_1.vue')
  },{
    path: '/res6/res61_2',
    name: 'res_res61',
    component: () => import('../views/res6/res61_2.vue')
  },{
    path: '/res6/res60_1',
    name: 'res_res61',
    component: () => import('../views/res6/res60_1.vue')
  },{
    path: '/res6/dongwu',
    name: 'res_res61',
    component: () => import('../views/res6/dongwu.vue')
  },{
    path: '/res6/res62',
    name: 'res_res62',
    component: () => import('../views/res6/res62.vue')
  },{
    path: '/res6/res62_1',
    name: 'res_res62',
    component: () => import('../views/res6/res62_1.vue')
  },{
    path: '/res6/res62_2',
    name: 'res_res62',
    component: () => import('../views/res6/res62_2.vue')
  },{
    path: '/test/chadan',
    name: 'res_chadan',
    component: () => import('../views/test/chadan.vue')
  },{
    path: '/res6/fk',
    name: 'res_res62',
    component: () => import('../views/res6/fk.vue')
  },{
    path: '/vpage',
    name: 'vpage',
    component: () => import('../views/vpage.vue')
  },
  {
    path: '/clear',
    name: 'clear',
    component: () => import('../views/clear.vue')
  },
  {
  
    path: '*',
    name: '404',
    component: () => import('../views/404.vue')
  }
]


const router = new VueRouter({

  mode:'history',
  routes
})


export default router
